html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  overscroll-behavior-y: contain;
  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;
  font-feature-settings: "ss01" on;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
	font-family: 'Inter var', 'Inter', sans-serif;
}

html, body, #root {
  height: 100%
}

path.slice{
  stroke-width:2px;
}

polyline{
  opacity: .35;
  stroke-width: 1px;
  fill: none;
}

button {
   font-family: 'Inter', sans-serif; 
}

#tooltip-donut {
     position: absolute;
     text-align: left;
     padding: 6px 8px 6px 8px;
     background:#E9E9E9;
     color: #000000;
     border: 1px solid #E0E0E0;
     border-radius: 2px;
     pointer-events: none;
     font-size: 12px;
     font-weight: 400;
     box-shadow: 0px 2px 5px 0px #0000001A;
     max-width: 200px;
}

.mobile-dashboard-content path {
  fill-opacity: 1;
}

.mobile-dashboard-content input,
.mobile-dashboard-content textarea,
.mobile-dashboard-content button,
.mobile-dashboard-content select,
.mobile-dashboard-content a {
  -webkit-tap-highlight-color: transparent;
}

#chartjs-tooltip {
  transform: translate3d(0, 0, 0);
}

.chartjs-container.dark {
  color: #FFF;
}

.grecaptcha-badge { 
    visibility: hidden;
}

@media print {
  html, body {
    height: auto !important;
    overflow: visibile !important;
  }
}
